import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  Toolbar,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';

const API_BASE_URL = '/hd-api/olo-ha';

const HouseAccountUserTable = ({ firebaseUser, setLoading }) => {
  const [users, setUsers] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('FirstName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  // Fetch users
  const fetchUsers = async () => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`${API_BASE_URL}/users`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
      });
      console.log(response.data);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Sorting logic
  const handleSortRequest = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Pagination handlers
  const handlePageChange = (event, newPage) => setPage(newPage);
  const handleRowsPerPageChange = (event) =>
    setRowsPerPage(parseInt(event.target.value, 10));

  // Select row handler
  const handleRowSelect = (id) => {
    setSelectedUsers((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  // Open dialog for adding a new user
  const handleAddUserClick = () => {
    setEditingUser({
      FirstName: '',
      LastName: '',
      Email: '',
      HouseAccountName: '',
      HouseAccountID: '',
      NUID: '',
      GLString: '',
      Approver: '',
    });
    setOpenDialog(true);
  };

  // Open dialog for editing
  const handleEditClick = (user) => {
    setEditingUser(user);
    setOpenDialog(true);
  };

  // Delete user handler
  const handleDeleteClick = async (id) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        await axios.delete(`${API_BASE_URL}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        });
        setUsers(
          users.filter((user) => user.OloCateringHouseAccountUsersSkey !== id)
        );
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  // Handle form submission for creating/updating users
  const handleSubmit = async () => {
    const method = editingUser?.OloCateringHouseAccountUsersSkey
      ? 'put'
      : 'post';
    const endpoint = editingUser?.OloCateringHouseAccountUsersSkey
      ? `${API_BASE_URL}/users/${editingUser.OloCateringHouseAccountUsersSkey}`
      : `${API_BASE_URL}/users`;

    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      await axios({
        method,
        url: endpoint,
        data: { ...editingUser, CreatedBy: firebaseUser.email },
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
      });
      setOpenDialog(false);
      setEditingUser(null);
      fetchUsers(); // Refresh list
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  // Delete selected users handler
  const handleDeleteSelectedUsers = async () => {
    if (window.confirm('Are you sure you want to delete the selected users?')) {
      const selectedIds = Object.keys(selectedUsers)
        .filter((id) => selectedUsers[id])
        .map((id) => Number(id));

      try {
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        await Promise.all(
          selectedIds.map((id) =>
            axios.delete(`${API_BASE_URL}/users/${id}`, {
              headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
            })
          )
        );
        setUsers(
          users.filter(
            (user) =>
              !selectedIds.includes(user.OloCateringHouseAccountUsersSkey)
          )
        );
        setSelectedUsers({});
      } catch (error) {
        console.error('Error deleting selected users:', error);
      }
    }
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      {/* Toolbar with "Add User" button */}
      <Toolbar>
        <Button
          variant='contained'
          color='primary'
          startIcon={<Add />}
          onClick={handleAddUserClick}
        >
          Add User
        </Button>
        {Object.values(selectedUsers).some((isSelected) => isSelected) && (
          <Button
            variant='contained'
            color='error'
            startIcon={<Delete />}
            onClick={handleDeleteSelectedUsers}
            sx={{ marginLeft: 2 }}
          >
            Delete Selected Users
          </Button>
        )}
      </Toolbar>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding='checkbox'>
              <Checkbox />
            </TableCell>
            {[
              'First',
              'Last',
              'Email',
              'House Account Name',
              'House Account ID',
              'NUID',
              'GLString',
              'Approver',
              'Actions',
            ].map((column) => (
              <TableCell key={column}>
                {column !== 'Actions' ? (
                  <TableSortLabel
                    active={orderBy === column}
                    direction={order}
                    onClick={() => handleSortRequest(column)}
                  >
                    {column}
                  </TableSortLabel>
                ) : (
                  column
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user) => (
              <TableRow key={user.OloCateringHouseAccountUsersSkey}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={
                      selectedUsers[user.OloCateringHouseAccountUsersSkey] ||
                      false
                    }
                    onChange={() =>
                      handleRowSelect(user.OloCateringHouseAccountUsersSkey)
                    }
                  />
                </TableCell>
                <TableCell>{user.FirstName}</TableCell>
                <TableCell>{user.LastName}</TableCell>
                <TableCell>{user.Email}</TableCell>
                <TableCell>{user.HouseAccountName || 'N/A'}</TableCell>
                <TableCell>{user.HouseAccountID || 'N/A'}</TableCell>
                <TableCell>{user.NUID || 'N/A'}</TableCell>
                <TableCell>{user.GLString || 'N/A'}</TableCell>
                <TableCell>{user.Approver || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(user)}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      handleDeleteClick(user.OloCateringHouseAccountUsersSkey)
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component='div'
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      {/* User Form Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{editingUser ? 'Edit User' : 'Add User'}</DialogTitle>
        <DialogContent>
          <TextField
            label='First Name'
            fullWidth
            margin='dense'
            value={editingUser?.FirstName || ''}
            onChange={(e) =>
              setEditingUser({ ...editingUser, FirstName: e.target.value })
            }
          />
          <TextField
            label='Last Name'
            fullWidth
            margin='dense'
            value={editingUser?.LastName || ''}
            onChange={(e) =>
              setEditingUser({ ...editingUser, LastName: e.target.value })
            }
          />
          <TextField
            label='Email'
            fullWidth
            margin='dense'
            value={editingUser?.Email || ''}
            onChange={(e) =>
              setEditingUser({ ...editingUser, Email: e.target.value })
            }
          />
          <TextField
            label='House Account Name'
            fullWidth
            margin='dense'
            value={editingUser?.HouseAccountName || ''}
            onChange={(e) =>
              setEditingUser({
                ...editingUser,
                HouseAccountName: e.target.value,
              })
            }
          />
          <TextField
            label='House Account ID* (required)'
            fullWidth
            margin='dense'
            value={editingUser?.HouseAccountID || ''}
            onChange={(e) =>
              setEditingUser({
                ...editingUser,
                HouseAccountID: e.target.value,
              })
            }
          />
          <TextField
            label='NUID'
            fullWidth
            margin='dense'
            value={editingUser?.NUID || ''}
            onChange={(e) =>
              setEditingUser({ ...editingUser, NUID: e.target.value })
            }
          />
          <TextField
            label='GL String'
            fullWidth
            margin='dense'
            value={editingUser?.GLString || ''}
            onChange={(e) =>
              setEditingUser({ ...editingUser, GLString: e.target.value })
            }
          />
          <TextField
            label='Approver'
            fullWidth
            margin='dense'
            value={editingUser?.Approver || ''}
            onChange={(e) =>
              setEditingUser({ ...editingUser, Approver: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSubmit} variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default HouseAccountUserTable;
