import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

const EmailDialog = ({
  couponInfo,
  storeName,
  storeId,
  emailAddress,
  name,
  confirmSend,
  open,
  onClose,
}) => {
  const dateSent = new Date();
  const expirationDate = new Date(dateSent);
  expirationDate.setDate(expirationDate.getDate() + 90);

  // Format expiration date
  const expirationDateString = `${
    expirationDate.getMonth() + 1
  }/${expirationDate.getDate()}/${expirationDate.getFullYear()}`;

  let s = `Let Us Make It Right: A Free ${couponInfo[0].couponSelection} for Your Next Mendo Order`;
  let b = `Hi ${name},

Thanks so much for reaching out to us! We’re really sorry that your recent Catering order didn’t meet the high standards you expect from Mendo. We know how frustrating that must have been after choosing us as your trusted caterer, and we’re so sorry we let you down.

We appreciate you letting us know and giving us the chance to improve! To make it right, we’d love to treat you to something on us. Below is a code you can use for your next Catering order. Simply enter the code in the Coupon field at checkout to redeem.

The code is valid until ${expirationDateString}, and may be used for a Catering order from any Mendocino Farms location.

FREE ${couponInfo[0].couponSelection}: {{offer_code}}

We look forward to serving you again soon and giving you the great Mendo experience you deserve!

Best,
Mendo ${storeName} Team

Each catering code is valid towards your next online catering  order. The code is valid only for orders placed online at https://mendocinofarmscatering.olo.com/ . Not valid for in-store or 3rd party orders. Limit one code per order. Code may not be used multiple times and has no cash value. Other restrictions may apply.`;
  if (couponInfo.length > 1) {
    s = `Let Us Make It Right: Free Treats for Your Next Mendo Catering`;
    b = `Hi ${name},

Thanks so much for reaching out to us! We’re really sorry that your recent Catering order didn’t meet the high standards you expect from Mendo. We know how frustrating that must have been after choosing us as your trusted caterer, and we’re so sorry we let you down.

We appreciate you letting us know and giving us the chance to improve! To make it right, we’d love to treat you to something on us. Below are two codes you can use for your future Catering orders. Simply enter the code in the Coupon field at checkout to redeem.

Please note, only one code can be used per order. The codes are valid until ${expirationDateString}, and may be used for a Catering order from any Mendocino Farms location.

FREE ${couponInfo[0].couponSelection}: {{offer_code}}
FREE ${couponInfo[1].couponSelection}: {{offer_code}}

We look forward to serving you again soon and giving you the great Mendo experience you deserve!

Best,
Mendo ${storeName} Team

Each catering code is valid towards your next online catering  order. The code is valid only for orders placed online at https://mendocinofarmscatering.olo.com/ . Not valid for in-store or 3rd party orders. Limit one code per order. Code may not be used multiple times and has no cash value. Other restrictions may apply.`;
  }
  const [to, setTo] = useState(emailAddress);
  const [replyTo, setReplyTo] = useState(
    `catering${storeId}@mendocinofarms.com`
  );
  const [subject, setSubject] = useState(s);
  const [body, setBody] = useState(b);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setStatus('');

    try {
      await confirmSend(to, replyTo, subject, body);
      setStatus('Email sent successfully!');
    } catch (error) {
      setStatus(`Error sending email: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog maxWidth='lg' open={open} onClose={onClose}>
      <DialogTitle>Email Offer Code</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          {status ? (
            <p style={{ fontSize: 16 }}>{status}</p>
          ) : (
            <React.Fragment>
              <TextField
                disabled
                autoFocus
                margin='dense'
                label='To'
                type='email'
                fullWidth
                variant='outlined'
                value={to}
                onChange={(e) => setTo(e.target.value)}
                required
              />
              <TextField
                disabled
                autoFocus
                margin='dense'
                label='Reply To'
                type='replyTo'
                fullWidth
                variant='outlined'
                value={replyTo}
                onChange={(e) => setReplyTo(e.target.value)}
                required
              />
              <TextField
                margin='dense'
                label='Subject'
                type='text'
                fullWidth
                variant='outlined'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <TextField
                margin='dense'
                label='Body'
                type='text'
                fullWidth
                variant='outlined'
                multiline
                rows={16}
                value={body}
                onChange={(e) => setBody(e.target.value)}
                required
              />
            </React.Fragment>
          )}
          <DialogActions>
            <Button onClick={onClose} color='error'>
              Cancel
            </Button>
            {status === 'Email sent successfully!' ? (
              <Button color='primary' onClick={onClose}>
                Close
              </Button>
            ) : (
              <Button type='submit' color='primary' disabled={loading}>
                {loading ? <CircularProgress size={24} /> : 'Send Email'}
              </Button>
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EmailDialog;
