import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Accordion,
  AccordionDetails,
  Box,
  TextField,
  Backdrop,
  CircularProgress,
  Typography,
} from '@mui/material';

import Table from './Table/Table';
import FilterActionBar from './FilterActionBar/FilterActionBar';
import Legend from './Legend';

const columnMap = {
  clientName: 'Booking Client Name',
  clientEmail: 'Booking Client Email',
  company: 'Company',
  storeId: 'Store ID',
  orders12mo: (
    <>
      Orders
      <br />
      (12 mo)
    </>
  ),
  amountSpent12mo: (
    <>
      Amt Spent
      <br />
      (12 mo)
    </>
  ),
  orders6mo: (
    <>
      Orders
      <br />
      (6 mo)
    </>
  ),
  amountSpent6mo: (
    <>
      Amt Spent
      <br />
      (6 mo)
    </>
  ),
  orders3mo: (
    <>
      Orders
      <br />
      (3 mo)
    </>
  ),
  amountSpent3mo: (
    <>
      Amt Spent
      <br />
      (3 mo)
    </>
  ),
  lastOrderDate: 'Last Order Date',
  export: '',
};

const Content = ({
  firebaseUser,
  role,
  formData,
  selectedOloStore,
  setSelectedOloStore,
  stores,
}) => {
  const [clients, setClients] = useState([]);
  const [clientTotalCount, setClientTotalCount] = useState(0);
  const [clientPage, setClientPage] = useState(0);
  const [clientRowsPerPage, setClientRowsPerPage] = useState(10);
  const [clientOrder, setClientOrder] = useState('desc');
  const [clientOrderBy, setClientOrderBy] = useState('timeWanted');
  // Update initial search field value to one of the allowed values.
  const [searchField, setSearchField] = useState('clientName');
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [loading, setLoading] = useState(false);

  // New state for region filtering:
  const [selectedRegion, setSelectedRegion] = useState('All');

  // New state for the highlight filter (legend filter)
  const [highlightFilter, setHighlightFilter] = useState('');

  useEffect(() => {
    if (firebaseUser.getIdToken && formData) {
      handleGetClientReporting();
    }
  }, [
    selectedOloStore,
    selectedRegion, // re-run if region changes
    firebaseUser,
    clientPage,
    clientRowsPerPage,
    clientOrder,
    clientOrderBy,
    highlightFilter,
    // Optionally, you can add searchQuery here if you want auto-search on query change.
  ]);

  const handleGetClientReporting = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);

      // Compute the storeId parameter:
      const computedStoreId =
        selectedRegion && selectedRegion !== 'All'
          ? stores
              .filter((store) => store.region === selectedRegion)
              .map((store) => store.storeId)
              .join(',')
          : selectedOloStore;

      // Format the dates (assuming your API expects YYYY-MM-DD format)
      const formattedStartDate = startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : '';
      const formattedEndDate = endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : '';

      // Build the query string.
      let queryString = `/hd-api/olo/client-reporting?storeId=${computedStoreId}&page=${clientPage}&limit=${clientRowsPerPage}&sortBy=${clientOrderBy}&order=${clientOrder}`;
      if (formattedStartDate && formattedEndDate) {
        queryString += `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (highlightFilter) {
        queryString += `&highlightFilter=${highlightFilter}`;
      }
      if (searchQuery) {
        queryString += `&searchField=${searchField}&searchQuery=${encodeURIComponent(
          searchQuery
        )}`;
      }
      const response = await axios.get(queryString, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      // Expecting { clients: [...], totalCount: number }
      console.log(response.data);
      setClients(response.data.clients);
      setClientTotalCount(response.data.totalCount);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client reporting:', error);
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    // Optionally, reset searchField if desired.
  };

  const handleChangeSelectedStore = (e) => {
    setSelectedOloStore(e.target.value);
  };

  // New handler for region change
  const handleChangeSelectedRegion = (e) => {
    setSelectedRegion(e.target.value);
    // Optionally, reset store selection if needed.
  };

  const handleSearch = async () => handleGetClientReporting();

  const handleExport = async () => {
    // Implementation for export (handled separately)
  };

  const handleRequestSort = (event, property) => {
    const isAsc = clientOrderBy === property && clientOrder === 'asc';
    setClientOrder(isAsc ? 'desc' : 'asc');
    setClientOrderBy(property);
  };

  const handleChangeSearchField = (e) => {
    const { value } = e.target;
    // Reset search query when the search field changes (optional)
    setSearchQuery('');
    // Optionally reset dates if required:
    setStartDate();
    setEndDate();
    setSearchField(value);
  };

  const handleChangeSearchQuery = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === 'timeWanted' || orderBy === 'timePlaced') {
      return new Date(b[orderBy]) - new Date(a[orderBy]);
    }
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array, comparator) => {
    if (array?.length > 0) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    } else {
      return [];
    }
  };

  const handleChangeClientPage = (event, newPage) => {
    setClientPage(newPage);
  };

  const handleChangeClientRowsPerPage = (event) => {
    setClientRowsPerPage(parseInt(event.target.value, 10));
    setClientPage(0);
  };

  // legendFilter should be one of "noOrders90days", "noOrders60days", "noOrders30days", or empty to clear.
  const handleLegendFilter = (legendFilter) => {
    // Toggle off if clicking the same active filter.
    setHighlightFilter((prev) => (prev === legendFilter ? '' : legendFilter));
  };

  let showStoreFilterFlag = false;
  if (
    role === 'DO' ||
    role === 'Basecamp' ||
    (role === 'Direct' && !formData.selectedStore)
  ) {
    showStoreFilterFlag = true;
  }
  const searchInput = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {/* <Typography mt={1} ml={2} variant='subtitle2'>
        Last Order Date:
      </Typography> */}
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText='Start Date'
        customInput={
          <TextField
            size='small'
            style={{ backgroundColor: 'white', marginTop: 8 }}
          />
        }
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        placeholderText='End Date'
        customInput={
          <TextField
            size='small'
            style={{ backgroundColor: 'white', marginTop: 8 }}
          />
        }
      />
    </div>
  );

  return (
    <div>
      <div style={{ width: '100%' }}>
        <Legend
          onLegendClick={handleLegendFilter}
          activeFilter={highlightFilter}
        />
      </div>
      <FilterActionBar
        firebaseUser={firebaseUser}
        handleKeyPress={handleKeyPress}
        handleChangeSearchQuery={handleChangeSearchQuery}
        searchField={searchField}
        searchQuery={searchQuery}
        handleChangeSearchField={handleChangeSearchField}
        handleExport={handleExport}
        searchInput={searchInput}
        handleSearch={handleSearch}
        handleClear={handleClear}
        showStoreFilter={showStoreFilterFlag}
        selectedOloStore={selectedOloStore}
        handleChangeSelectedStore={handleChangeSelectedStore}
        stores={stores}
        highlightFilter={highlightFilter}
        // Pass the new region props to FilterActionBar:
        selectedRegion={selectedRegion}
        handleChangeSelectedRegion={handleChangeSelectedRegion}
        showRegionFilter={true}
        setLoading={setLoading}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
        }}
      >
        <Accordion
          defaultExpanded
          style={{
            width: '100%',
            backgroundColor: '#f8f8f8',
            marginBottom: 0,
          }}
        >
          <AccordionDetails>
            <Table
              firebaseUser={firebaseUser}
              order={clientOrder}
              totalCount={clientTotalCount}
              orderBy={clientOrderBy}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, 'client')
              }
              onPageChange={handleChangeClientPage}
              onRowsPerPageChange={handleChangeClientRowsPerPage}
              rowsPerPage={clientRowsPerPage}
              getComparator={getComparator}
              stableSort={stableSort}
              page={clientPage}
              columnMap={columnMap}
              clients={clients}
              setLoading={setLoading}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Content;
