import React, { useState } from 'react';
import axios from 'axios';
import {
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  InputBase,
  NativeSelect,
  Paper,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/FileDownload';
import SellIcon from '@mui/icons-material/Sell';

import ImportCouponsDialog from './ImportCouponsDialog';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const FilterActionBar = ({
  firebaseUser,
  searchField,
  handleChangeSearchField,
  handleExport,
  searchInput,
  handleSearch,
  handleClear,
  showStoreFilter,
  selectedOloStore,
  handleChangeSelectedStore,
  stores,
}) => {
  const [importCouponsDialogOpen, setImportCouponsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenImportCouponsDialog = () => {
    setImportCouponsDialogOpen(true);
  };

  const handleSearchTables = () => {
    handleSearch();
  };

  const handleImportCoupons = async (couponCodes) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      setLoading(true);
      const response = await axios.post(
        `/hd-api/olo/coupons/import`,
        {
          couponCodes,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      console.log(response.data);
      // Handle success
    } catch (error) {
      console.log(error);
      // Handle error
    } finally {
      setLoading(false);
      setImportCouponsDialogOpen(false);
    }
  };

  let isBrandiOrRJ =
    firebaseUser.email === 'rjdeutschman21@gmail.com' ||
    firebaseUser.email === 'bsanders@mendocinofarms.com';
  return (
    <Paper style={{ backgroundColor: '#f8f8f8' }}>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        style={{ margin: '24px 0 0 0' }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={2}
          lg={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '16px',
            paddingBottom: 12,
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={8}
          lg={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: 12,
          }}
        >
          {showStoreFilter && (
            <FormControl size='small'>
              <InputLabel id='store-label'>Store</InputLabel>
              <NativeSelect
                value={selectedOloStore}
                onChange={handleChangeSelectedStore}
                label='Store'
                input={<BootstrapInput />}
              >
                {stores.map(({ storeId, storeName }) => (
                  <option key={storeId} value={storeId}>
                    {storeName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
          <FormControl size='small'>
            <InputLabel id='search-field-label'>Search Field</InputLabel>
            <NativeSelect
              value={searchField}
              onChange={handleChangeSearchField}
              label='Search Field'
              input={<BootstrapInput />}
            >
              <option value='orderNumber'>Original Order #</option>
              <option value='timeWanted'>Original Order Date</option>
              <option value='menuItem'>Offer</option>
              <option value='reason'>Reason</option>
              <option value='lastSent'>Last Sent</option>
              <option value='redeemed'>Used/Not Used</option>
              <option value='redeemedOrder'>Redeemed Order #</option>
              <option value='name'>Booking Client Name</option>
              <option value='email'>Booking Client Email</option>
              <option value='phoneNumber'>Phone</option>
            </NativeSelect>
          </FormControl>
          {searchInput}
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [250, 20],
                    },
                  },
                ],
              },
            }}
            title='Search'
          >
            <IconButton onClick={handleSearchTables} color='primary'>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [265, 20],
                    },
                  },
                ],
              },
            }}
            title='Reset'
          >
            <IconButton onClick={handleClear} color='info'>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            paddingBottom: 12,
            paddingRight: 32,
          }}
          item
          xs={12}
          sm={6}
          md={2}
          lg={2}
        >
          {isBrandiOrRJ && (
            <Tooltip
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [450, 20],
                      },
                    },
                  ],
                },
              }}
              title='Import Offer Codes'
            >
              <IconButton
                onClick={handleOpenImportCouponsDialog}
                color='default'
              >
                <SellIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [450, 20],
                    },
                  },
                ],
              },
            }}
            title='Export to XLSX'
          >
            <IconButton onClick={handleExport} color='default'>
              <ExportIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        {importCouponsDialogOpen && (
          <ImportCouponsDialog
            open={importCouponsDialogOpen}
            onClose={() => setImportCouponsDialogOpen(false)}
            onImport={handleImportCoupons}
            loading={loading}
          />
        )}
      </Grid>
    </Paper>
  );
};

export default FilterActionBar;
