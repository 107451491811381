import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Paper,
  Collapse,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import ExportIcon from '@mui/icons-material/FileDownload';

import { useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function numberWithCommas(x) {
  if (!x) return 0;
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const ClientTable = ({
  firebaseUser,
  clients,
  order,
  orderBy,
  totalCount,
  handleRequestSort,
  onPageChange,
  onRowsPerPageChange,
  rowsPerPage,
  page,
  columnMap,
  setLoading,
}) => {
  const navigate = useNavigate();

  // Data is already sorted/paginated from the server.
  const displayedRows = clients;

  const handleSortRequest = (property) => (event) => {
    handleRequestSort(event, property);
  };

  // Component for an individual row with expandable child orders.
  const RowWithCollapse = ({ clientData, index }) => {
    const [open, setOpen] = useState(false);
    const [orders, setOrders] = useState(null);
    const [loadingOrders, setLoadingOrders] = useState(false);

    // Child orders sorting state.
    const [childSortBy, setChildSortBy] = useState('dateOrdered');
    const [childOrder, setChildOrder] = useState('desc');

    // Determine row background color based on flags.
    let rowColor = 'inherit',
      hoverColor = '#f5f5f5';
    if (clientData.noOrders90days === 1) {
      rowColor = '#ff000024';
      hoverColor = '#ff000042';
    } else if (clientData.noOrders60days === 1) {
      rowColor = '#fff44e54';
      hoverColor = '#fff44e8f';
    } else if (clientData.noOrders30days === 1) {
      rowColor = '#4caf505e';
      hoverColor = '#4caf5075';
    }

    // Apply striped background if no custom color is set.
    const computedRowColor =
      rowColor === 'inherit'
        ? index % 2 === 0
          ? '#f9f9f9'
          : '#ffffff'
        : rowColor;
    const computedHoverColor =
      rowColor === 'inherit'
        ? index % 2 === 0
          ? '#f1f1f1'
          : '#f5f5f5'
        : hoverColor;

    // When a row is expanded, fetch the orders if they haven't been loaded yet.
    useEffect(() => {
      if (open && orders === null) {
        getOrders();
      }
    }, [open, orders, clientData.clientEmail]);

    const handleViewInvoice = (exportBatchId, invoiceNumber) => {
      window.open(
        `https://beta.supporthappy.com/olo-invoice/${exportBatchId}/${invoiceNumber}`,
        '_blank'
      );
    };

    const getOrders = async () => {
      try {
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        setLoadingOrders(true);
        axios
          .get(
            `/hd-api/olo/client-reporting/store=${clientData.storeId}/client=${clientData.clientEmail}`,
            {
              headers: {
                Authorization: `Bearer ${firebaseUserIdToken}`,
              },
              'Content-Type': 'application/json',
            }
          )
          .then((response) => {
            setOrders(response.data.orders);
          })
          .catch((error) => {
            console.error('Error fetching orders:', error);
            setOrders([]);
          })
          .finally(() => {
            setLoadingOrders(false);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // Handler to update child sorting.
    const handleChildSort = (column) => {
      if (childSortBy === column) {
        setChildOrder(childOrder === 'asc' ? 'desc' : 'asc');
      } else {
        setChildSortBy(column);
        setChildOrder('asc');
      }
    };

    // Sort orders client-side.
    const sortedOrders = orders
      ? [...orders].sort((a, b) => {
          if (childSortBy === 'orderId') {
            return childOrder === 'asc'
              ? a.orderId.localeCompare(b.orderId)
              : b.orderId.localeCompare(a.orderId);
          } else if (childSortBy === 'dateOrdered') {
            return childOrder === 'asc'
              ? new Date(a.dateOrdered) - new Date(b.dateOrdered)
              : new Date(b.dateOrdered) - new Date(a.dateOrdered);
          } else if (childSortBy === 'totalAmont') {
            return childOrder === 'asc'
              ? a.totalAmont - b.totalAmont
              : b.totalAmont - a.totalAmont;
          }
          return 0;
        })
      : [];

    const exportOrders = async () => {
      try {
        setLoading(true);
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);

        // Build the query string.
        let queryString = `/hd-api/olo/client-reporting/store=${clientData.storeId}/client=${clientData.clientEmail}/export`;

        // Make the API call to download the file.
        const response = await axios.get(queryString, {
          headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
          responseType: 'blob',
        });

        // Create a blob from the response data and trigger a download.
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'client-report.xlsx'); // You can change the filename as needed.
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error exporting client data:', error);
      }
    };

    return (
      <>
        <TableRow
          sx={{
            backgroundColor: computedRowColor,
            '&:hover': { backgroundColor: computedHoverColor },
          }}
        >
          {/* Expand/Collapse Icon */}
          <TableCell>
            <IconButton size='small' onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{clientData.clientName}</TableCell>
          <TableCell>{clientData.clientEmail}</TableCell>
          <TableCell>{clientData.storeId}</TableCell>
          <TableCell>{clientData.orders12mo}</TableCell>
          <TableCell>
            ${numberWithCommas(Number(clientData.amountSpent12mo).toFixed(2))}
          </TableCell>
          <TableCell>{clientData.orders6mo}</TableCell>
          <TableCell>
            ${numberWithCommas(Number(clientData.amountSpent6mo).toFixed(2))}
          </TableCell>
          <TableCell>{clientData.orders3mo}</TableCell>
          <TableCell>
            ${numberWithCommas(Number(clientData.amountSpent3mo).toFixed(2))}
          </TableCell>
          <TableCell>{clientData.lastOrderDate}</TableCell>
          <TableCell>
            <Tooltip title='Export Orders'>
              <IconButton onClick={exportOrders} color='default'>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <Box margin={1}>
                {loadingOrders ? (
                  <Box display='flex' alignItems='center'>
                    <CircularProgress size={20} />
                    <Typography variant='body2' sx={{ ml: 1 }}>
                      Loading orders...
                    </Typography>
                  </Box>
                ) : orders && orders.length > 0 ? (
                  <Table size='small' aria-label='orders'>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sortDirection={
                            childSortBy === 'orderId' ? childOrder : false
                          }
                        >
                          <TableSortLabel
                            active={childSortBy === 'orderId'}
                            direction={
                              childSortBy === 'orderId' ? childOrder : 'asc'
                            }
                            onClick={() => handleChildSort('orderId')}
                          >
                            Order ID
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sortDirection={
                            childSortBy === 'orderId' ? childOrder : false
                          }
                        >
                          <TableSortLabel
                            active={childSortBy === 'orderId'}
                            direction={
                              childSortBy === 'orderId' ? childOrder : 'asc'
                            }
                            onClick={() => handleChildSort('orderId')}
                          >
                            Company
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sortDirection={
                            childSortBy === 'dateOrdered' ? childOrder : false
                          }
                        >
                          <TableSortLabel
                            active={childSortBy === 'dateOrdered'}
                            direction={
                              childSortBy === 'dateOrdered' ? childOrder : 'asc'
                            }
                            onClick={() => handleChildSort('dateOrdered')}
                          >
                            Date Ordered
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          sortDirection={
                            childSortBy === 'dateOrdered' ? childOrder : false
                          }
                        >
                          <TableSortLabel
                            active={childSortBy === 'timeWanted'}
                            direction={
                              childSortBy === 'timeWanted' ? childOrder : 'asc'
                            }
                            onClick={() => handleChildSort('timeWanted')}
                          >
                            Time Wanted
                          </TableSortLabel>
                        </TableCell>
                        <TableCell
                          align='right'
                          sortDirection={
                            childSortBy === 'totalAmont' ? childOrder : false
                          }
                        >
                          <TableSortLabel
                            active={childSortBy === 'totalAmont'}
                            direction={
                              childSortBy === 'totalAmont' ? childOrder : 'asc'
                            }
                            onClick={() => handleChildSort('totalAmont')}
                          >
                            Total Amount
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedOrders.map((order, idx) => {
                        const childRowColor =
                          idx % 2 === 0 ? '#f9f9f9' : '#ffffff';
                        const childHoverColor =
                          idx % 2 === 0 ? '#f1f1f1' : '#f5f5f5';
                        return (
                          <TableRow
                            key={idx}
                            sx={{
                              backgroundColor: childRowColor,
                              '&:hover': { backgroundColor: childHoverColor },
                            }}
                          >
                            <TableCell align='left'>
                              <Button
                                onClick={() =>
                                  handleViewInvoice(
                                    order.exportBatchNumber,
                                    order.orderId
                                  )
                                }
                              >
                                {order.orderId}
                              </Button>
                            </TableCell>
                            <TableCell>{order.company}</TableCell>

                            <TableCell>{order.dateOrdered}</TableCell>
                            <TableCell>{order.timeWanted}</TableCell>
                            <TableCell align='right'>
                              $
                              {numberWithCommas(
                                Number(order.totalAmount).toFixed(2)
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography variant='body2'>No orders found.</Typography>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            {/* Empty cell for the expand/collapse icon */}
            <TableCell />
            {[
              'clientName',
              'clientEmail',
              // 'company',
              'storeId',
              'orders12mo',
              'amountSpent12mo',
              'orders6mo',
              'amountSpent6mo',
              'orders3mo',
              'amountSpent3mo',
              'lastOrderDate',
              'export',
            ].map((column) => (
              <TableCell key={column}>
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={handleSortRequest(column)}
                >
                  {columnMap[column]}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedRows.map((clientData, index) => (
            <RowWithCollapse
              key={index}
              clientData={clientData}
              index={index}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TableContainer>
  );
};

export default ClientTable;
