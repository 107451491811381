import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { UserAuthContextProvider } from './context/UserAuthContext';
import { CategoryContextProvider } from './context/CategoryContext';
import { SearchProvider } from './context/SearchContext';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './views/Home/Home';
import Form from './views/Form/Form';
import UploadHTML from './views/UploadHTML/UploadHTML';
import Login from './views/Login/Login';
import ChangeStore from './views/ChangeStore/ChangeStore';
import PricingComp from './views/PricingComp/PricingComp';
import FacilitiesWalkthrough from './views/FacilitiesWalkthrough/FacilitiesWalkthrough';
import EditProfile from './views/EditProfile/EditProfile';
import Subcategory from './views/Subcategory/Subcategory';
import OloInvoiceAdjustment from './views/OLOInvoiceAdjustment/OLOInvoiceAdjustment';
import OLOLookup from './views/OLOLookup/OLOLookup';
import CouponManagement from './views/CouponManagement/CouponManagement';
import ClientReporting from './views/ClientReporting/ClientReporting';
import ViewOLOInvoice from './views/ViewOLOInvoice/ViewOLOInvoice';
import HouseAccountUsers from './views/HouseAccountUsers/HouseAccountUsers';
import Redirect from './Redirect';

function App() {
  return (
    <UserAuthContextProvider>
      <CategoryContextProvider>
        <SearchProvider>
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path='/home'
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/olo-invoice-adjustment'
                element={
                  <ProtectedRoute>
                    <OloInvoiceAdjustment />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/olo-house-account-users'
                element={
                  <ProtectedRoute>
                    <HouseAccountUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/olo-invoice/:exportBatchId/:oloInvoiceNumber'
                element={
                  <ProtectedRoute>
                    <ViewOLOInvoice />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/offer-code-mgmt'
                element={
                  <ProtectedRoute>
                    <CouponManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/olo-lookup'
                element={
                  <ProtectedRoute>
                    <OLOLookup />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/client-reporting'
                element={
                  <ProtectedRoute>
                    <ClientReporting />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/change-store'
                element={
                  <ProtectedRoute>
                    <ChangeStore />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/facilities-walkthrough'
                element={
                  <ProtectedRoute>
                    <FacilitiesWalkthrough />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/category/:categoryId'
                element={
                  <ProtectedRoute>
                    <Subcategory />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/category/:categoryId/form/:jotformId'
                element={
                  <ProtectedRoute>
                    <Form />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/category/:categoryId/subcategory/:subcategoryId/form/:jotformId'
                element={
                  <ProtectedRoute>
                    <Form />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/upload-html'
                element={
                  <ProtectedRoute>
                    <UploadHTML />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/pricing-comp'
                element={
                  <ProtectedRoute>
                    <PricingComp />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/profile'
                element={
                  <ProtectedRoute>
                    <EditProfile />
                  </ProtectedRoute>
                }
              />
              <Route path='/redirect' element={<Redirect />} />
              <Route path='/' element={<Login />} />
            </Routes>
          </BrowserRouter>
        </SearchProvider>
      </CategoryContextProvider>
    </UserAuthContextProvider>
  );
}

export default App;
