import React, { useMemo } from 'react';
import {
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  InputBase,
  NativeSelect,
  Paper,
  Tooltip,
  TextField,
} from '@mui/material';
import moment from 'moment';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/FileDownload';
import SearchIcon from '@mui/icons-material/Search';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const FilterActionBar = ({
  searchQuery,
  handleChangeSearchQuery,
  handleKeyPress,
  searchField,
  handleSearch,
  handleChangeSearchField,
  firebaseUser,
  startDate,
  endDate,
  clientOrder,
  clientOrderBy,
  searchInput,
  highlightFilter,
  handleClear,
  showStoreFilter,
  selectedOloStore,
  handleChangeSelectedStore,
  stores,
  selectedRegion,
  handleChangeSelectedRegion,
  showRegionFilter = true,
  setLoading,
}) => {
  const regionOptions = useMemo(() => {
    const regions = stores
      .filter((store) => store.region)
      .map((store) => store.region);
    const uniqueRegions = Array.from(new Set(regions)).sort();
    return ['All', ...uniqueRegions];
  }, [stores]);

  // Filter store options based on the selected region.
  const filteredStores = useMemo(() => {
    if (!showStoreFilter) return [];
    if (selectedRegion && selectedRegion !== 'All') {
      return stores.filter(
        (store) => store.storeId === 'all' || store.region === selectedRegion
      );
    }
    return stores;
  }, [stores, selectedRegion, showStoreFilter]);

  const handleExport = async () => {
    setLoading(true);
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      // Compute the storeId parameter based on selected region or store.
      const computedStoreId =
        selectedRegion && selectedRegion !== 'All'
          ? stores
              .filter((store) => store.region === selectedRegion)
              .map((store) => store.storeId)
              .join(',')
          : selectedOloStore;

      // Format the start and end dates (assuming API expects YYYY-MM-DD).
      const formattedStartDate = startDate
        ? moment(startDate).format('YYYY-MM-DD')
        : '';
      const formattedEndDate = endDate
        ? moment(endDate).format('YYYY-MM-DD')
        : '';

      // Build the query string.
      let queryString = `/hd-api/olo/client-reporting/export?storeId=${computedStoreId}&sortBy=${clientOrderBy}&order=${clientOrder}`;
      if (formattedStartDate && formattedEndDate) {
        queryString += `&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (highlightFilter) {
        queryString += `&highlightFilter=${highlightFilter}`;
      }

      // Make the API call to download the file.
      const response = await axios.get(queryString, {
        headers: { Authorization: `Bearer ${firebaseUserIdToken}` },
        responseType: 'blob',
      });

      // Create a blob from the response data and trigger a download.
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'client-report.xlsx'); // You can change the filename as needed.
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error exporting client data:', error);
    }
  };

  return (
    <Paper style={{ backgroundColor: '#f8f8f8' }}>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        style={{ margin: '24px 0 0 0' }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: 12,
          }}
        >
          {showRegionFilter && (
            <Grid item xs={12} sm={6} md={2} lg={2}>
              <FormControl size='small' fullWidth>
                <InputLabel id='region-label'>Region</InputLabel>
                <NativeSelect
                  value={selectedRegion}
                  onChange={handleChangeSelectedRegion}
                  label='Region'
                  input={<BootstrapInput />}
                >
                  {regionOptions.map((region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
          )}
          {showStoreFilter && (
            <FormControl size='small'>
              <InputLabel id='store-label'>Store</InputLabel>
              <NativeSelect
                value={selectedOloStore}
                onChange={handleChangeSelectedStore}
                label='Store'
                input={<BootstrapInput />}
              >
                {filteredStores.map(({ storeId, storeName }) => (
                  <option key={storeId} value={storeId}>
                    {storeName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
          <FormControl size='small'>
            <InputLabel id='search-field-label'>Search Field</InputLabel>
            <NativeSelect
              value={searchField}
              onChange={handleChangeSearchField}
              label='Search Field'
              input={<BootstrapInput />}
            >
              <option value='clientName'>Booking Client Name</option>
              <option value='clientEmail'>Booking Client Email</option>
              <option value='organizationName'>Company</option>
            </NativeSelect>
          </FormControl>
          <TextField
            sx={{ backgroundColor: 'white', marginTop: 1 }}
            size='small'
            value={searchQuery}
            name='searchQuery'
            onChange={handleChangeSearchQuery}
            onKeyPress={handleKeyPress}
          />

          {searchInput}
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [250, 20],
                    },
                  },
                ],
              },
            }}
            title='Search'
          >
            <IconButton onClick={handleSearch} color='primary'>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [265, 20],
                    },
                  },
                ],
              },
            }}
            title='Reset'
          >
            <IconButton onClick={handleClear} color='info'>
              <ClearIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [450, 20],
                    },
                  },
                ],
              },
            }}
            title='Export to XLSX'
          >
            <IconButton onClick={handleExport} color='default'>
              <ExportIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterActionBar;
