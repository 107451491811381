import React, { useState } from 'react';
import {
  Backdrop,
  Container,
  CircularProgress,
  Typography,
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { useMediaQuery } from 'react-responsive';

import { useUserAuth } from '../../context/UserAuthContext';

import Header from '../../components/Header/Header';
import Logo from '../../components/Logo/Logo';
import Table from './Table/Table';

import './HouseAccountUsers.css';

const Main = () => {
  const { user: firebaseUser, loading: authenticating } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState('');

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  let content;
  if (authenticating) {
    content = (
      <div style={{ marginTop: '10%', width: '100%', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  } else {
    content = (
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography
          style={{ fontFamily: 'Lato', fontSize: 34, marginBottom: 32 }}
        >
          Olo Catering House Account Users
        </Typography>
        <Table
          firebaseUser={firebaseUser}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    );
  }

  return (
    <div>
      <Header isTabletOrMobile={isTabletOrMobile} />
      <Container maxWidth='xl'>
        <Logo isTabletOrMobile={isTabletOrMobile} showAlert={showAlert} />
        <div id='main'></div>
        {content}
      </Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
};

export default Main;
