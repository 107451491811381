import React from 'react';
import {
  IconButton,
  FormControl,
  Grid,
  InputLabel,
  InputBase,
  NativeSelect,
  Paper,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ExportIcon from '@mui/icons-material/FileDownload';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
    maxWidth: 'unset',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const FilterActionBar = ({
  selectedReport,
  searchField,
  handleChangeSearchField,
  handleChangeSelectedReport,
  handleExport,
  searchInput,
  handleSearch,
  handleClear,
  showStoreFilter,
  selectedOloStore,
  handleChangeSelectedStore,
  stores,
}) => {
  const handleSearchTables = () => {
    handleSearch();
  };

  // Updated reportFieldsMap with dynamic search fields
  const reportFieldsMap = {
    redeemed: [
      { value: 'originalOrder', label: 'Original Order #' },
      {
        value: 'originalOrderTimeWanted',
        label: 'Original Order Date',
      },
      { value: 'redeemedOrder', label: 'Redeemed Order #' },
      { value: 'redeemedOrderTimeWanted', label: 'Redeemed Order Date' },

      { value: 'menuItem', label: 'Menu Item' },
    ],
    unused: [
      { value: 'originalOrder', label: 'Original Order #' },
      {
        value: 'originalOrderTimeWanted',
        label: 'Original Order Date',
      },
      { value: 'reason', label: 'Reason' },
      { value: 'menuItem', label: 'Menu Item' },
      { value: 'dateSent', label: 'Original Email Date Sent' },
      { value: 'lastSent', label: 'Reminder Email Date Sent' },
    ],
    common: [
      { value: 'reason', label: 'Most Common Mistakes / Reasons' },
      { value: 'reasonCount', label: 'Occurrences' },
      { value: 'dateSent', label: 'Original Email Date Sent' },
      {
        value: 'originalOrderTimeWanted',
        label: 'Original Order Date',
      },
      {
        value: 'redeemedOrderTimeWanted',
        label: 'Redeemed Order Date',
      },
    ],
    most: [
      { value: 'codeCount', label: 'Total Codes Generated' },
      { value: 'dateSent', label: 'Original Email Date Sent' },
      {
        value: 'originalOrderTimeWanted',
        label: 'Original Order Date',
      },
      {
        value: 'redeemedOrderTimeWanted',
        label: 'Redeemed Order Date',
      },
    ],
  };

  const searchFields = reportFieldsMap[selectedReport] || [];

  return (
    <Paper style={{ backgroundColor: '#f8f8f8' }}>
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='center'
        style={{ margin: '24px 0 0 0', width: '100%' }}
      >
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: 12,
          }}
        ></Grid>
        <Grid
          item
          xs={8}
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '16px',
            paddingBottom: 12,
          }}
        >
          <FormControl size='small'>
            <InputLabel id='store-label'>Reports</InputLabel>
            <NativeSelect
              value={selectedReport}
              onChange={handleChangeSelectedReport}
              label='Reports'
              input={<BootstrapInput />}
            >
              <option value={'redeemed'}>Redeemed Codes</option>
              <option value={'unused'}>Unused Codes</option>
              <option value={'common'}>Most Common Mistakes</option>
              <option value={'most'}>Stores with Most Codes</option>
            </NativeSelect>
          </FormControl>
          {showStoreFilter && (
            <FormControl size='small'>
              <InputLabel id='store-label'>Store</InputLabel>
              <NativeSelect
                value={selectedOloStore}
                onChange={handleChangeSelectedStore}
                label='Store'
                input={<BootstrapInput />}
              >
                {stores.map(({ storeId, storeName }) => (
                  <option key={storeId} value={storeId}>
                    {storeName}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          )}
          <FormControl size='small'>
            <InputLabel id='search-field-label'>Search Field</InputLabel>
            <NativeSelect
              value={searchField}
              onChange={handleChangeSearchField}
              label='Search Field'
              input={<BootstrapInput />}
            >
              {searchFields.map((field) => (
                <option key={field.value} value={field.value}>
                  {field.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {searchInput}
          <Tooltip title='Search'>
            <IconButton onClick={handleSearchTables} color='primary'>
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Reset'>
            <IconButton onClick={handleClear} color='info'>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={2}
          container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '16px',
            paddingBottom: 12,
            paddingRight: 32,
          }}
        >
          <Tooltip
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [450, 20],
                    },
                  },
                ],
              },
            }}
            title='Export to XLSX'
          >
            <IconButton onClick={handleExport} color='default'>
              <ExportIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterActionBar;
