import React from 'react';
import { Box, Typography } from '@mui/material';

const Legend = ({ onLegendClick, activeFilter }) => {
  const legendItems = [
    {
      filter: 'noOrders90days',
      label: 'No orders in last 90 days',
      bgColor: '#ff000024',
      borderColor: '#ff000042',
    },
    {
      filter: 'noOrders60days',
      label: 'No orders in last 60 days',
      bgColor: '#fff44e54',
      borderColor: '#fff44e8f',
    },
    {
      filter: 'noOrders30days',
      label: 'No orders in last 30 days',
      bgColor: '#4caf505e',
      borderColor: '#4caf5075',
    },
  ];

  return (
    <Box display='flex' justifyContent='center' mb={2} mt={2}>
      <Box display='flex' gap={2} alignItems='center'>
        {legendItems.map((item) => (
          <Box
            key={item.filter}
            display='flex'
            alignItems='center'
            gap={1}
            sx={{
              cursor: 'pointer',
              padding: '2px',
              borderRadius: 2,
              border: activeFilter === item.filter ? '2px solid black' : 'none',
            }}
            onClick={() => onLegendClick(item.filter)}
          >
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: item.bgColor,
                border: `1px solid ${item.borderColor}`,
              }}
            />
            <Typography variant='body2'>{item.label}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Legend;
