import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { CategoryContext } from '../../context/CategoryContext';

const buttonColors = [
  { label: 'Support', color: 'info' },
  { label: 'Draw Attention', color: 'error' },
  { label: 'Function', color: 'warning' },
];

const SupportCategories = ({ firebaseUser, categories }) => {
  const navigate = useNavigate();
  const { updateCategory } = useContext(CategoryContext);

  const [iframeUrl, setIframeUrl] = useState(null);

  const handleButtonClick = async (
    name,
    jotformId,
    url,
    buttonColor,
    subCategories,
    id
  ) => {
    if (name === 'FacilitiesWalkthrough') {
      navigate('/facilities-walkthrough');
    }
    if (name === 'OLOInvoiceAdjustment') {
      navigate('/olo-invoice-adjustment');
    }
    if (name === 'OLOLookup') {
      navigate('/olo-lookup');
    }
    if (name === 'CompetitorPricingProject') {
      navigate(`/pricing-comp`);
    }
    if (name === 'Olo Catering HA Users') {
      navigate('/olo-house-account-users');
      return;
    }
    if (subCategories && subCategories.length > 0) {
      updateCategory({
        category: name,
        jotformId,
        id,
        url,
        buttonColor,
        subCategories,
      });
      navigate(`/category/${id}`);
    }
    if (buttonColor === 'Function' && name === 'Upload HTML') {
      navigate('/upload-html');
    }
    if (jotformId) {
      updateCategory({
        category: name,
        jotformId,
        id,
        url,
        buttonColor,
        subCategories,
      });
      navigate(`/category/${id}/form/${jotformId}`);
    }
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      window.location.href = url;
    } else if (url) {
      try {
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        const response = await axios.get(`/hd-api/public/${url}`, {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        });
        // Set the HTML content in the iframe
        setIframeUrl(`data:text/html;base64,${btoa(response.data)}`);
      } catch (error) {
        console.error('Error fetching HTML file:', error);
      }
    }
  };

  const buttons = categories.map(({ fields, id }, index) => {
    const { ButtonName, Name, JotFormID, subDepartments, ...rest } = fields;
    const bcText = rest['Button Color'];
    const url = rest['Web Page Destination'];
    const color = buttonColors.find((b) => b.label === bcText);
    return (
      <Grid item key={index}>
        <Button
          size='large'
          variant='contained'
          color={color ? color.color : 'info'}
          style={{
            fontFamily: 'Lato',
            minWidth: 240,
            height: '100%',
          }}
          onClick={() =>
            handleButtonClick(Name, JotFormID, url, bcText, subDepartments, id)
          }
        >
          {ButtonName}
        </Button>
      </Grid>
    );
  });
  return (
    <div style={{ width: '100%', marginTop: 8 }}>
      <Grid
        container
        style={{ margin: 'auto' }}
        justifyContent='center'
        xs={12}
      >
        {iframeUrl ? (
          // Render iframe when the iframeUrl is set
          <iframe
            src={iframeUrl}
            title='Embedded Content'
            width='100%'
            height='500px'
            style={{ border: 'none' }}
          />
        ) : (
          // Render the original content with buttons
          <Paper style={{ margin: 16, padding: 24 }}>
            <Typography
              style={{
                marginBottom: 24,
                textAlign: 'center',
                fontFamily: 'Lato',
              }}
              variant='h5'
            >
              Which category best suits your support request?
            </Typography>
            <Grid xs={12} item container justifyContent='center' spacing={2}>
              {buttons}
              {/* <Grid item>
                <Button
                  size='large'
                  variant='contained'
                  color={'info'}
                  style={{
                    fontFamily: 'Lato',
                    width: 280,
                  }}
                  onClick={() => navigate(`/olo-lookup`)}
                >
                  OLO Order Lookup
                </Button>
              </Grid> */}
            </Grid>
          </Paper>
        )}
      </Grid>
    </div>
  );
};

export default SupportCategories;
